<template>
	<!--预约场地交付弹窗-->
	<div class="Y-tips">
		<el-dialog class='diyModel' title="选择通知人员" :visible.sync="$attrs.isOpen" :close-on-click-modal="false" width="568px" :before-close="closeMypup">
			<div >
				<TForm  ref="htmlFrom" lableRow :autoCheck='true' labelPosition='left' :model="htmlDatas" :formlist="formlist" label-width='88px' :rowGutter='16'></TForm>
				<div class="btmBtn" >
		    		<el-button class="searchbtn" @click='closeMypup'> 取消</el-button>
		    		<el-button type="primary" @click='validateFroms' :disabled='isHttping'> 确定</el-button>
		        </div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from "js-pinyin";
	import TForm from '@/components/YTable/TForm.vue';
	export default {
		props: {
			datas: {
				type: Object,
				default: ()=>{
					return {
						rentOrderId:'',
					}
				},
			},
		},
		components: {TForm},
		data() {
			return {
				htmlDatas:{},
  			isHttping:false,
  			formlist:[
  				{name: '交付地点',keys: 'deliverAddress',value: '',type: 'input',isMust:true,},
  				{name: '交付时间',keys: 'deliverDate',value: '',type: 'time',isMust:true,pickerType:'datetime',format:'timestamp'},
  				{name: '选择通知人',keys: 'tzUserIds',isKey:true,value: '',type: 'employe',isMust:true,flex:14,checkProps:{
  					multiple: true,value:'userId',label:'userName',children:'children',emitPath: false
  				}},
  				{name: '通知方式',keys: 'notificationMethod',value: '1',type: 'select',flex:10,options:[{name:'发送短信通知',code:'1'}]},
  			],
			}
		},
		
		computed: {},
		watch:{},
		created() {
			this.$store.dispatch('getEmployeeDatas');//获取所有部门人员
			this.resetTFrom();
		},
		mounted() {
			this.$nextTick(()=>{})
			
		},
		updated() {},
		beforeDestroy() {},
		methods: {
			closeMypup(){
				this.resetTFrom();
				this.$emit('close')
			},
			resetTFrom(){
	  			for(let item of this.formlist) { //初始化赋值我的表单数据
					this.$set(this.htmlDatas, item.keys, item.value);
				}
  		},
			validateFroms(){
				let allow=this.$refs.htmlFrom.validate(isOk=>{//进行校验
					if(isOk){
						this.getFromDatas();
					}else{
						this.$message({message:'请检查输入是否正确',type:'warning'})
					}
				})
			},
			async getFromDatas(){
				this.isHttping=true;
				setTimeout(()=>{
					this.isHttping=false;
				},2000)
				let params={
					...this.htmlDatas,
					rentOrderId:this.datas.rentOrderId,
				}
				if(params.tzUserIds){
					params.notificationUserId=params.tzUserIds.join(',');
					delete params.tzUserIds
				}
				if(params.deliverDate) params.deliverDate=new Date(params.deliverDate).getTime();
				let res = await this.ApiHttp('/organization/enterprise/rent/deliver/createDeliver',params,'post');
				if(res){
					this.$refs.htmlFrom.resetFields();
					this.$emit('close','init')
				}
			},
			
			
		},

	}
</script>

<style lang="scss" scoped>
.btmBtn{
	text-align: right;
}

</style>