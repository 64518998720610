<template>
	<!--企业入驻-->
	<mainBox >
		<div class="wapBox">
			<div >
				<div class="eBtns">
					<el-button class='b_it' @click='toAddEntrance()' type="primary" >发起入驻</el-button>
					<el-button class='b_it' @click='refreshTable' icon="el-icon-refresh-right">刷新</el-button>
					<el-button class='b_it sxBtn' @click.stop='showRowQuery'>筛选
					 	<i class="el-icon-arrow-down" v-if="rowQuery"></i>
        		<i class="el-icon-arrow-right" v-else></i>
					</el-button>
				</div>
				<div class="tbBox ">
					<TTable2 class='' ref="myTable" :tbloading='tbloading' :border='false' :showQuery='rowQuery' @rowSeach='rowSeach' :tableData="tableData" :tableTitle="tableTitle">
						<template slot='dateSlot' slot-scope="scope">
							<span> {{scope.row.rentDateStr}}</span>
						</template>
						<template slot='rentStatusStr' slot-scope="scope">
							<span :class="'txColor'+scope.row.rentStatus"> {{scope.row.rentStatusStr}}</span>
						</template>
						<template slot='operating' slot-scope="scope">
							<!--入驻状态 0待审核1待入驻2入驻中 3已入驻 4已驳回-->
							<el-button type="text" @click='tbRowDetail(scope.row)'>查看</el-button>
							<!---->
							<el-button type="text" v-if='scope.row.rentStatus==1'  @click.stop="tbRowRetion(scope.row)" >预约场地交付</el-button>
							<el-button type="text" v-if='scope.row.rentStatus==2' @click.stop="tbRowDelivery(scope.row)" >交付场地</el-button>
							<!--<el-button type="text" v-if='scope.row.rentStatus==3' @click='tbRowDel(scope.row)'>删除</el-button>-->
							<el-button type="text" v-if='scope.row.rentStatus==4' @click.stop="toAddEntrance(scope.row)" >重新提交</el-button>
						</template>
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
			<Reservation v-if='yyjfInfo.open' :isOpen='yyjfInfo.open' :datas='yyjfInfo.datas' @close='closeDeliver'  />
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import { envInfo } from "@/constants/envInfo";
	import Reservation from './model/Reservation.vue'
	const statusArrs=[
		//入驻状态 0待审核1待入驻2入驻中 3已入驻 4已驳回
		{label:'待审核',value:0},
		{label:'待入驻',value:1},
		{label:'入驻中',value:2},
		{label:'已入驻',value:3},
		{label:'已驳回',value:4},
	]
	export default {
		mixins: [pagination, seachAndTable],
		components: {TTable2,TPagination,Reservation},
		data() {
			return {
				filterText: '',
				treeData: [],
				treeProps: {
					label: 'materielCategoryName',
					children: 'projectChildren',
				},
				tbBkSeach:true,
				tableTitle: [
					{
						name: '创建时间',
						prop: "creationDateStr",
					},
					{
						name: '企业名称',
						prop: "enterpriseName",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '项目名称',
						prop: "projectName",
						queryInfo: {
							type:'select',
							value: "",
							options:[],
						}
					},
					{
						name: '入驻时间',
						prop: "rentDate",
						slotName:'dateSlot',
						queryInfo: {
							type: 'date',
							valueFormat:'timestamp',
							value: "",
						}
						
					},
					{
						name: '入驻联系人',
						prop: "liaison",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '入驻状态',
						prop: "rentStatus",
						slotName:'rentStatusStr',
						queryInfo: {
							type:'select',
							value:'',
							options:statusArrs,
						}
					},
					{name: '责任部门',prop: "deptName",},
					{
						name: '操作',
						width: "180",
						fixed: 'right',
						slotName: "operating",
					},
				],
				tableData: [],
				yyjfInfo: { //预约场地交付
					open: false,
					datas:{},
				},
			};
		},
		watch: {
			filterText(val) {
				this.$refs.myTree.filter(val);
			}
		},
		created() {
			this.initProjectArrs();
		},
		mounted() {},
		computed: {},
		methods: {
			async initProjectArrs(){
				let res =await this.$store.dispatch('getAllProjects');
				if(res){
  				res.map(it=>{
						it.label = it.projectName;
						it.value = it.projectId;
					})
					this.common.injectionOption2('projectName',res,this.tableTitle)
				}
			},
			tbRowRetion(row){//预约场地交付
				this.yyjfInfo={
					open: true,
					datas:row,
				}
			},
			tbRowDelivery(row){
				let params = {
	        path: "/rwork/enterprise/deliverySite",
	        query: {
	        	rentArea:row.rentArea,
	        	positionName:row.positionName,
	        	rentOrderId: row.rentOrderId,
	        	enterpriseId:row.enterpriseId,
	        	rentDeliverId:row.rentDeliverId,
	        },
	      };
	      this.$router.push(params);
			},
			tbRowDetail(row){
				let params = {
	        path: "/rwork/enterprise/detail",
	        query: {
	          orderId: row?row.rentOrderId:'',
	        },
	      };
	      this.$router.push(params);
			},
			toAddEntrance(row){
				let params = {
	        path: "/rwork/enterprise/sendUp",
	        query: {
	          orderId: row?row.rentOrderId:null,
	        },
	      };
	      this.$router.push(params);
			},
			closeDeliver(isInit) {//关闭场地交付弹窗时
				this.yyjfInfo.open=false;
				if(isInit){
					this.initTable();
				}
			},
			async initTable() {
//				if(!this.actvedNode) return;
				this.tbloading = true;
				let params = {
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
//					materielCategoryId:this.initTbPid,
					...this.rowFilterData,
				}
				if(params.projectName) params.projectId=params.projectName;
				delete params.projectName;
				let res = await this.ApiHttp('/organization/enterprise/rent/page', params);
				let Arrs=res.rows||[];
				Arrs.map(it=>{
					it.rentStatusStr=statusArrs[+it.rentStatus].label;
					it.rentDateStr=this.common.getTimeMmss(it.rentDate,'day');
					it.creationDateStr=this.common.getTimeMmss(it.creationDate);
					it.deptName='招商部'
				})
				this.tableData = Arrs
				this.tableParams.total = res.total;
				this.$nextTick(() => {
					this.tbloading = false;
				})
			},
			tbRowDel(row) {
				this.$confirm('是否确认删除', '提示').then(() => {
					this.ApiHttp('/base/tenant/materiel/delete', row, 'delete').then(res => {
						if(res) {
							this.initTable();
						}
					});
				}).catch(() => {});
			},
			//项目相关 end
		},
	};
</script>

<style scoped lang="scss">
	.wapBox {
		height: 100%;
		.flexs {
			height: 100%;
		}

		.right {
			padding: 16px 24px;
			.eBtns {
				position: relative;
				.el-button {
					padding: 10px 18px;
				}
				.sxBtn{
					position: absolute;
					right: 0;
				}
			}
		}
		.txColor0{
	    padding: 0 6px;
	   	color: #4e78ff;
    	background: #f4f6fe;
		}
		.txColor3{
	    padding: 0 6px;
	   	color: #78d549;
    	background: #f2faee;
		}
		.txColor4{
	    padding: 0 6px;
	   	color: #e8331c;
  		background: #fef5f4;
		}
	}
</style>
<style type="text/css">
	.tbTreeCheck thead .el-checkbox {
		display: none;
	}
	
	.materielBox .m-body {
		padding: 0 !important;
	}
	.dfNode1 {
		.el-tree-node__label {
			color: #1A4CEC;
		}
	}
	
	.drBtn.el-button--text {
		color: #2F3941 !important;
	}
</style>